<script lang="ts" setup>
import type { LightCashResponse } from "@/types";

const props = defineProps<{
	lastPayProvider: LightCashResponse["lastPayProvider"];
}>();
defineEmits<{ (event: "openCash"): void }>();

const { t } = useT();
const providerIcon = ref(props.lastPayProvider?.data?.cardData?.brand ?? "");
</script>
<template>
	<div v-if="lastPayProvider" class="pay-method">
		<template v-if="lastPayProvider.type === 'trustly'">
			<NuxtImg :src="lastPayProvider.data?.logo" width="44" :alt="lastPayProvider.type" />
			<AText class="info" variant="toledo" as="div">
				<span>{{ lastPayProvider.data?.title }}</span>
				<span class="expiration">
					<AText class="token-name" :modifiers="['ellipsis']">{{ lastPayProvider.data?.tokenName }}</AText>
					<span>&dash;{{ lastPayProvider.data?.tokenNumber }}</span>
				</span>
			</AText>
		</template>
		<template v-else>
			<img
				:src="`/nuxt-img/payment-brand/${providerIcon}.png`"
				width="44"
				:alt="providerIcon"
				@error="providerIcon = 'new-card'"
			/>
			<AText class="info" variant="toledo" as="div">
				<span>**** **** **** {{ lastPayProvider.data?.cardData?.last4 }}</span>
				<span class="expiration">
					{{ lastPayProvider.data?.cardData?.exp?.month }}/{{ lastPayProvider.data?.cardData?.exp?.year?.substring(2) }}
				</span>
			</AText>
		</template>

		<AText :modifiers="['underline', 'link']" @click="$emit('openCash')">{{ t("Change") }}</AText>
	</div>
</template>
<style lang="scss" scoped>
.pay-method {
	height: 64px;
	padding: gutter(1.25) gutter(1);
	border-radius: 10px;
	border: 1px solid var(--chuzhou);
	box-shadow: 0 3px 2px 0 var(--cimahi);
	display: flex;
	align-items: center;
	gap: gutter(1);

	.info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		color: var(--changshu);
		line-height: 21px;

		.expiration {
			opacity: 0.6;
			color: var(--canberra);
		}

		.token-name {
			max-width: 120px;
			display: inline-block;
			vertical-align: middle;
		}
	}

	span.link {
		align-self: flex-start;
		margin-left: auto;
	}
}
</style>
